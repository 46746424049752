import React, { useMemo } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
const CheatingScoreRenderer = ({ params }) => {
	const { row: report } = params;
	const score = report?.cheatingScore ?? {};

	return (
		<div className="flex justify-center items-center h-full">
			<FlagComponent score={score?.likelihood_of_cheating ?? "-"} verdict={score?.verdict} />
		</div>
	);
};

export const FlagComponent = ({ score, verdict = "" }) => {
	const [color, finalSummary] = useMemo(() => {
		return getFinalSummaryTextForACS(score, verdict);
	}, [score, verdict]);
	return (
		<Tooltip title={score === "-" ? "Cheating Score Unavailable" : <> {finalSummary}</>}>
			<FlagIcon className={clsx(color)} />
		</Tooltip>
	);
};

export default CheatingScoreRenderer;

export const getFinalSummaryTextForACS = (score, verdict) => {
	let color = "text-gray-400";
	let note = "";
	let likelihoodMeter = "";
	if (score < 40) {
		color = "text-green-400";
		note = "Note: Review manually to rule out false negatives.";
		likelihoodMeter = "LOW";
	} else if (score >= 40 && score < 60) {
		color = "text-orange-400";
		note = "Note: Review manually to rule out false positives.";
		likelihoodMeter = "MEDIUM";
	} else if (score >= 60) {
		color = "text-red-600";
		note = "Note: Review manually to rule out false positives.";
		likelihoodMeter = "HIGH";
	}
	const finalSummary = (
		<>
			<h6 className="text-sm font-medium mb-1">
				Cheating Likelihood: {verdict ? verdict : likelihoodMeter}{" "}
				<span className="font-light">({score / 10})</span>
			</h6>
			<p className="text-xs font-semibold">{note}</p>
		</>
	);
	return [color, finalSummary];
};
