import { Button, Card, CardContent, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { TextAreaFormInput } from "./text-area-form-input";
import { useCreateNewCheatingScoreMutation } from "../../features/api/apiSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";

export const DevOptions = ({ isMakingNewEvalRequest, onSubmit }) => {
	const [currentTab, setCurrentTab] = useState(1);
	return (
		<div className="flex flex-col gap-2 p-4">
			<div className="flex flex-row gap-2 justify-start items-center " role="tablist">
				<TabOption
					onClick={() => {
						setCurrentTab(0);
					}}
					isCurrentSelected={currentTab === 0}
				>
					Evaluation
				</TabOption>
				<TabOption
					onClick={() => {
						setCurrentTab(1);
					}}
					isCurrentSelected={currentTab === 1}
				>
					Cheating
				</TabOption>
			</div>
			{currentTab === 0 && (
				<EvaluateForm isMakingNewEvalRequest={isMakingNewEvalRequest} onSubmit={onSubmit} />
			)}
			{currentTab === 1 && <CheatingScoreForm />}
		</div>
	);
};

const TabOption = ({ children, onClick, isCurrentSelected }) => {
	return (
		<button
			role="tab"
			onClick={onClick}
			className={clsx(
				"bg-transparent border-none outline-none cursor-pointer text-base focus-within:text-green-500 hover:text-green-400",
				isCurrentSelected ? "text-green-500" : "",
			)}
		>
			{children}
		</button>
	);
};

export const EvaluateForm = ({ isMakingNewEvalRequest, onSubmit }) => {
	const methods = useForm({
		defaultValues: {
			customEvaluationPrompt: {
				questionScore: `Score that is awarded to the candidate's answer for the current question in percentage out of 100. Try to understand the context of the response and give score accordingly. If the candidate's answer lacks depth, don't give them high scores. The depth of the answer should be considered while awarding scores. If the answer lacks depth or is vague, give them a lower score accordingly. If an answer is not given, you can award 0.`,
				overallScore: `The overall score in percentage out of 100 that you would award to the candidate based on his/her interview performance for the given job role. For technical interviews, Behavioural questions should not be considered in the overall_score_in_percentage, only technical questions that assess the candidate's technical skills should be considered while calculating the overall score. Please be strict in your evaluation. Don't be lazy or in a hurry, take your time to go through the answers and then award the over all score.`,
				skillRating: `The rating you would award to the candidate for the corresponding skill, out of 5. Strict scrutiny must be made while awarding ratings for each skill. Make sure your ratings align with the candidates responses and his knowledge on the skill. This will give the recruiter and the employee an idea about the candidate's expertise in the current skill.`,
			},
		},
	});

	const { handleSubmit } = methods;

	return (
		<Card>
			<CardContent>
				<FormProvider {...methods}>
					<h2>Get Custom Evaluation</h2>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack>
							<TextAreaFormInput
								id="customEvaluationPrompt.questionScore"
								label="Question Score Evaluation Prompt"
								tooltipText="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							/>
							<TextAreaFormInput
								id="customEvaluationPrompt.overallScore"
								label="Overall Score Evaluation Prompt"
								tooltipText="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							/>
							<TextAreaFormInput
								id="customEvaluationPrompt.skillRating"
								label="Skill Rating Evaluation Prompt"
								tooltipText="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							/>
							<div className="flex flex-row gap-3 ">
								<Button
									variant="contained"
									disabled={isMakingNewEvalRequest}
									onClick={handleSubmit(onSubmit)}
								>
									{isMakingNewEvalRequest ? "Evaluating..." : "GET EVALUATION"}
								</Button>
							</div>
						</Stack>
					</form>
				</FormProvider>
			</CardContent>
		</Card>
	);
};

const cheatingScoreDefValues = {
	prompt: `You are an advanced AI trained to analyze interview transcripts to detect potential cheating scenarios. 
Your task is to evaluate the responses provided by a candidate and determine the likelihood of cheating based on the following criteria:
 	- Identify if the responses sound overly structured and text book like or if they are using chatgpt like tools to answer the questions in the interview.
	- Ignore coding tasks if correct since they may also be provided using external assistance. 
	- Ignore the cases where the candidate opts to skip the questions. This is natural as the candidate can skip multiple questions for various reasons. Do not consider this while analyzing potential cheating cases.`,
};

const CheatingScoreForm = () => {
	const [newScore, { isLoading }] = useCreateNewCheatingScoreMutation();
	const { interviewReportId } = useParams();

	const methods = useForm({
		defaultValues: cheatingScoreDefValues,
	});
	const { handleSubmit } = methods;

	async function getCheatingScoreForReport(data) {
		try {
			const res = await newScore({ interviewReportId, prompt: data.prompt }).unwrap();
			toast.success("Cheating score generated");
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<Card>
			<CardContent>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(getCheatingScoreForReport)}>
						<h2>Anti Cheating Score</h2>

						<TextAreaFormInput
							label={"Anti cheating score prompt"}
							tooltipText={""}
							id={"prompt"}
							rows={8}
						/>
						<Button variant="contained" type="submit" disabled={isLoading}>
							{isLoading ? "Creating" : "Create"} Cheating Score
						</Button>
					</form>
				</FormProvider>
			</CardContent>
		</Card>
	);
};
