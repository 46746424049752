import CheatingScoreRenderer from "./columnRenderer/CheatingScoreRenderer";
import getNumberColumnFilters from "./columnRenderer/filters/NumberColumnFilter";
import { getCheatingScore } from "./columnRenderer/filters/valueGetters";
import { numberSorter } from "./columnRenderer/filters/valuesSorter";

export const antiCheatingScoreColumnDef = {
	field: "cheatingScore",
	headerName: "AI-Cheating Score",
	headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
	width: 150,
	headerAlign: "center",
	renderCell: (params) => <CheatingScoreRenderer params={params} />,
	filterOperators: getNumberColumnFilters(getCheatingScore),
	sortComparator: numberSorter,
	valueGetter: (_, row) => {
		return row?.cheatingScore?.likelihood_of_cheating ?? 0;
	},
};
