import React, { useEffect, useRef, useState } from "react";
import "./videoTiles.css";
import {
	ConsoleLogger,
	DefaultDeviceController,
	LogLevel,
	DefaultMeetingSession,
	MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { updateSecondDevice } from "../../utilities/interviewReportsApi";
import { Box, Typography } from "@mui/material";
import NetworkModal from "./NetworkModal";
import {
	createMeeting,
	deleteAttendee,
	endMeeting,
	faceDetection,
	joinMeeting,
} from "../../utilities/awsApi";
import { gptVision } from "../../Common";
import AdminUI from "./Proctor/AdminUI";
import SidePanel from "./Proctor/SidePanel";
// import { gptVision } from '../../Common';

const VideoTiles = (props) => {
	// let meetingEvents = [];
	// const { meetingEvents, setMeetingEvents } = props;
	const [isScreenShared, setIsScreenShared] = useState(false);
	const [isMicOn, setIsMicOn] = useState(false);
	const [isVideoOn, setIsVideoOn] = useState(false);
	const [isTabAudioMuted, setIsTabAudioMuted] = useState(false);
	const params = useParams();
	const [meetingSession, setMeetingSession] = useState(null);
	const [meetingObj, setMeetingObj] = useState(null);
	const [attendeeObj, setAttendeeObj] = useState(null);
	const [attendees, setAttendees] = useState(new Map());
	const [meetingAlreadyStarted, setMeetingAlreadyStarted] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalAlreadyShown, setModalAlreadyShown] = useState(false);
	const captureIntervalId = useRef(null);
	const captureTimeoutId = useRef(null);
	const remSecondsIntervalId = useRef(null);
	const [remSecondsForSd, setRemSecondsForSd] = useState(null);
	const [audioDevices, setAudioDevices] = useState([]);
	const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
	const currentAudioDevicesRef = useRef([]);

	// const attemptReconnection = async () => {
	// 	if (!meetingSession) {
	// 		console.log("No active meeting session available for reconnection");
	// 		return;
	// 	} else {
	// 		console.log("Attempting to reconnect...");
	// 		return;
	// 	}

	// 	// try {
	// 	// 	await meetingSession.audioVideo.chooseAudioInputDevice(props.audioInputDeviceId);
	// 	// 	await meetingSession.audioVideo.chooseVideoInputDevice(props.videoInputDeviceId);
	// 	// 	meetingSession.audioVideo.restartLocalVideo(() => {
	// 	// 		console.log("Local video restarted successfully");
	// 	// 	});
	// 	// 	meetingSession.audioVideo.start();
	// 	// } catch (error) {
	// 	// 	console.error("Failed to reconnect: ", error);
	// 	// 	props.setSnackbarMessage("Failed to reconnect. Please check your devices and network settings.");
	// 	// 	props.setSnackbarOpen(true);
	// 	// }
	// };

	// const reestablishStreams = async () => {
	// 	if (props.micStream && props.videoStream) {
	// 		// await meetingSession.audioVideo.startAudioInput(props.micStream);
	// 		// await meetingSession.audioVideo.startVideoInput(props.videoStream);
	// 		// console.log("Audio and video streams restarted.");
	// 		console.log("props.micStream and props.videoStream available to restart.");
	// 	} else {
	// 		console.log("No local streams available to restart.");
	// 	}
	// };

	useEffect(() => {
		const updateAudioInput = async () => {
			if (meetingSession && meetingSession.audioVideo && props.micStream) {
				try {
					// Using startAudioInput to start using the new mic stream
					await meetingSession.audioVideo.startAudioInput(props.micStream);
					// console.log("Chime audio input updated with new mic stream.");
					props.clientLogger("Chime audio input updated with new mic stream.");
				} catch (error) {
					// console.error("Error updating Chime with new mic stream:", error);
					props.clientLogger(`Error updating Chime with new mic stream: ${error}`);
				}
			}
		};

		updateAudioInput();
	}, [props.micStream]);

	useEffect(() => {
		const updateVideoInput = async () => {
			if (meetingSession && meetingSession.audioVideo && props.videoStream) {
				try {
					// Using startAudioInput to start using the new video stream
					await meetingSession.audioVideo.startVideoInput(props.videoStream);
					// console.log("Chime video input updated with new video stream.");
					props.clientLogger("Chime video input updated with new video stream.");
				} catch (error) {
					// console.error("Error updating Chime with new video stream:", error);
					props.clientLogger(`Error updating Chime with new video stream: ${error}`);
				}
			}
		};

		updateVideoInput();
	}, [props.videoStream]);

	const subscribeToAttendeeVolume = async (attendeeId) => {
		try {
			// Unsubscribe first to prevent duplicate subscriptions
			await meetingSession?.audioVideo?.realtimeUnsubscribeFromVolumeIndicator(attendeeId);

			const volumeIndicatorCallback = async (attendeeId, volume, muted, signalStrength) => {
				try {
					await new Promise((resolve) => {
						setAttendees((prev) => {
							const existingAttendee = prev.get(attendeeId) || {};
							const newMap = new Map(prev).set(attendeeId, {
								...existingAttendee,
								isSpeaking: volume > 0.1,
								isMuted: muted ?? existingAttendee.isMuted,
							});
							resolve(newMap);
							return newMap;
						});
					});
				} catch (error) {
					props.clientLogger(`Error in volumeIndicatorCallback: ${error}`);
				}
			};

			// Subscribe with new callback
			if (meetingSession?.audioVideo) {
				await meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
					attendeeId,
					volumeIndicatorCallback,
				);
			}
		} catch (error) {
			props.clientLogger(`Error in subscribeToAttendeeVolume: ${error}`);
			// Optionally rethrow if you want the error to propagate
			// throw error;
		}
	};

	const toggleMute = () => {
		// Toggle the isMuted state
		setIsTabAudioMuted(!isTabAudioMuted);

		// Get the audio element and toggle its muted property based on the isMuted state
		const audioElement = document.getElementById("audio");
		if (audioElement) {
			audioElement.muted = !isTabAudioMuted;
		}

		// Get all video elements and toggle their muted property based on the isMuted state
		const videoElements = document.querySelectorAll("video");
		videoElements.forEach((video) => {
			video.muted = !isTabAudioMuted;
		});
	};

	const handleVideo = async () => {
		try {
			if (isVideoOn) {
				await meetingSession?.audioVideo?.stopVideoInput();
				await meetingSession?.audioVideo?.stopLocalVideoTile();
				setIsVideoOn(false);
			} else {
				const videoInputs = await meetingSession?.audioVideo?.listVideoInputDevices();
				if (!videoInputs?.[0]?.deviceId) {
					throw new Error("No video input devices found");
				}

				await meetingSession?.audioVideo?.startVideoInput(videoInputs[0].deviceId);
				await meetingSession?.audioVideo?.startLocalVideoTile();
				setIsVideoOn(true);
			}
		} catch (error) {
			props.clientLogger(`Error in handleVideo: ${error}`);
		}
	};

	const handleMic = async () => {
		if (isMicOn) {
			await meetingSession.audioVideo.stopAudioInput();
			await meetingSession.audioVideo.realtimeMuteLocalAudio();
			setIsMicOn(false);
			// Clear devices when mic is turned off
			setAudioDevices([]);
			setSelectedAudioDevice(null);
		} else {
			const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
			setAudioDevices(audioInputs);
			const defaultDevice = audioInputs[0];
			setSelectedAudioDevice(defaultDevice);
			await meetingSession.audioVideo.startAudioInput(defaultDevice?.deviceId);
			await meetingSession.audioVideo.realtimeUnmuteLocalAudio();
			setIsMicOn(true);
		}
	};

	const handleAudioDeviceChange = async (e) => {
		const newDeviceId = e.target.value;
		const newDevice = audioDevices.find((d) => d.deviceId === newDeviceId);
		const newDeviceLabel = newDevice?.label || "Unknown Device";
		const oldDeviceLabel = selectedAudioDevice?.label || "Unknown Device";

		try {
			// Start the new audio input
			await meetingSession.audioVideo.startAudioInput(newDeviceId);

			// Update state first to prevent UI flicker
			setSelectedAudioDevice(newDevice);

			try {
				for (const attendee of Array.from(attendees.values())) {
					try {
						await subscribeToAttendeeVolume(attendee.id);
					} catch (error) {
						props.clientLogger(`Error subscribing to attendee ${attendee.id}: ${error}`);
						// Continue with next attendee even if one fails
						continue;
					}
				}
			} catch (error) {
				props.clientLogger(`Error in attendee subscription loop: ${error}`);
			}

			props.clientLogger("IDT Audio device changed successfully", {
				from: oldDeviceLabel,
				to: newDeviceLabel,
				isDefault: newDeviceId === "default",
			});

			// If we were unmuted before, stay unmuted
			if (isMicOn) {
				await meetingSession.audioVideo.realtimeUnmuteLocalAudio();
			}
		} catch (error) {
			props.clientLogger(`Error changing audio device: ${error}`, {
				attemptedDevice: newDeviceLabel,
			});

			// Revert to previous device if change fails
			if (selectedAudioDevice) {
				try {
					await meetingSession.audioVideo.startAudioInput(selectedAudioDevice.deviceId);
					setSelectedAudioDevice(selectedAudioDevice); // Revert UI state
					if (isMicOn) {
						await meetingSession.audioVideo.realtimeUnmuteLocalAudio();
					}
				} catch (revertError) {
					props.clientLogger(`Failed to revert to previous device: ${revertError}`, {
						failedRevertDevice: oldDeviceLabel,
					});
				}
			}
		}
	};

	// const getPOSTLogger = (meetingSessionConfiguration, appName, url, logLevel) => {
	// 	const options = {
	// 		url,
	// 		logLevel,
	// 		metaData: {
	// 			appName,
	// 			meetingId: meetingSessionConfiguration.meetingId,
	// 			attendeeId: meetingSessionConfiguration.credentials.attendeeId,
	// 		},
	// 	};
	// 	// console.log(`options: ${JSON.stringify(options)}`);
	// 	return new POSTLogger(options);
	// };

	// const createLogStream = async (configuration) => {
	// 	const body = JSON.stringify({
	// 		// action: 'MEETING_EVENTS',
	// 		meetingId: configuration.meetingId,
	// 		attendeeId: configuration.credentials.attendeeId,
	// 		// meetingEvents: meetingEvents,
	// 		source: `${
	// 			props.preferredName === 'Admin'
	// 				? 'Admin'
	// 				: props.preferredName === 'secondDevice'
	// 				? "Candidate's Second Device"
	// 				: 'Candidate'
	// 		}`,
	// 	});
	// 	try {
	// 		const response = await fetch(requestPath, {
	// 			method: 'POST',
	// 			body,
	// 		});
	// 		if (response.status === 200) {
	// 			console.log('log stream created');
	// 		}
	// 	} catch (error) {
	// 		//   fatal(error);
	// 		//   this.log(error.message);
	// 		console.error('Error during createLogStream:', error);
	// 	}
	// };

	// const logMeetingEvents = async () => {
	// 	if (meetingEvents.length > 0) {
	// 		try {
	// 			const response = await fetch(requestPath, {
	// 				method: 'POST',
	// 				headers: new Headers(),
	// 				body: JSON.stringify({
	// 					action: 'MEETING_EVENTS',
	// 					meetingEvents: meetingEvents,
	// 					source: `${
	// 						props.preferredName === 'Admin'
	// 							? 'Admin'
	// 							: props.preferredName === 'secondDevice'
	// 							? "Candidate's Second Device"
	// 							: 'Candidate'
	// 					}`,
	// 				}),
	// 			});
	// 			const data = await response.json();
	// 			console.log('Meeting events logs sent:', data);
	// 		} catch (error) {
	// 			console.error('Failed to send meeting events:', error);
	// 		}
	// 		setMeetingEvents([]);
	// 	} else {
	// 		console.log('meetingEvents.length <= 0');
	// 	}
	// };

	// useEffect(() => {
	// 	if (props.interviewEnded) {
	// 		// logMeetingEvents();
	// 		stopMeeting();
	// 	}
	// }, [props.interviewEnded]);

	const clearCaptureTimers = () => {
		if (captureIntervalId.current) {
			clearInterval(captureIntervalId.current);
			captureIntervalId.current = null;
		}
		if (captureTimeoutId.current) {
			clearTimeout(captureTimeoutId.current);
			captureTimeoutId.current = null;
		}
		if (remSecondsIntervalId.current) {
			clearInterval(remSecondsIntervalId.current);
			remSecondsIntervalId.current = null;
		}
	};

	useEffect(() => {
		const deviceObserver = {
			audioInputsChanged: async (freshDeviceList) => {
				if (freshDeviceList) {
					const newDevicesMap = new Map(freshDeviceList.map((device) => [device.deviceId, device]));
					const currentDevicesList = currentAudioDevicesRef.current;
					const currentDevicesMap = new Map(
						currentDevicesList.map((device) => [device.deviceId, device]),
					);

					// Find removed devices using the ref
					const removedDevices = currentDevicesList.filter(
						(device) => !newDevicesMap.has(device.deviceId),
					);

					// Find added devices
					const addedDevices = freshDeviceList.filter(
						(device) => !currentDevicesMap.has(device.deviceId),
					);

					// Update ref before state
					currentAudioDevicesRef.current = freshDeviceList;

					// Special handling for default device changes
					const defaultDevice = freshDeviceList.find((device) => device.deviceId === "default");
					const selectedWasRemoved =
						selectedAudioDevice &&
						(removedDevices.some((device) => device.deviceId === selectedAudioDevice.deviceId) ||
							(selectedAudioDevice.deviceId === "default" &&
								defaultDevice?.label !== selectedAudioDevice.label));

					// console.log("Device change debug:", {
					// 	selectedAudioDevice,
					// 	defaultDevice,
					// 	selectedWasRemoved,
					// 	removedDevices,
					// });

					if (selectedWasRemoved) {
						props.clientLogger("Selected audio device was removed or changed", {
							removedDevice: selectedAudioDevice.label,
							availableDevices: freshDeviceList.length,
							newDefaultDevice: defaultDevice?.label,
						});

						// Find new default device or first available device
						const newDefaultDevice = defaultDevice || freshDeviceList[0];
						if (newDefaultDevice) {
							// Use timeout to ensure state updates have propagated
							setTimeout(() => {
								handleAudioDeviceChange({ target: { value: newDefaultDevice.deviceId } });
							}, 0);
						}
					}

					// Update state last
					setAudioDevices(freshDeviceList);

					props.clientLogger("IDT Audio devices list updated", {
						deviceCount: freshDeviceList.length,
						added: addedDevices.map((d) => d.label || "Unknown Device"),
						removed: removedDevices.map((d) => d.label || "Unknown Device"),
						defaultDeviceLabel: defaultDevice?.label,
					});

					try {
						for (const attendee of Array.from(attendees.values())) {
							try {
								await subscribeToAttendeeVolume(attendee.id);
							} catch (error) {
								props.clientLogger(`Error subscribing to attendee ${attendee.id}: ${error}`);
								// Continue with next attendee even if one fails
								continue;
							}
						}
					} catch (error) {
						props.clientLogger(`Error in attendee subscription loop: ${error}`);
					}
				}
			},
		};

		// Register device change observer
		meetingSession?.deviceController &&
			isMicOn &&
			meetingSession?.deviceController?.addDeviceChangeObserver(deviceObserver);

		// Cleanup on component unmount or session change
		return () => {
			meetingSession?.deviceController &&
				meetingSession?.deviceController?.removeDeviceChangeObserver(deviceObserver);
		};
	}, [meetingSession, isMicOn, selectedAudioDevice]); // Added selectedAudioDevice to dependencies

	useEffect(() => {
		// stopMeeting();
		// props.isMobile &&
		// 	alert(
		// 		`VideoTiles useEffect called with props:
		// 		JSON.stringify(props) : ${JSON.stringify(props)} props: ${props}`
		// 	);
		// console.log(
		// 	`>>>>>> inside first useEffect of videoTiles : ${JSON.stringify(
		// 		props
		// 	)} `
		// );
		// console.log(`>>>props.micStream:`, props.micStream);
		// console.log(`>>>props.videoStream:`, props.videoStream);
		// console.log(`>>>props.screenStream:`, props.screenStream);

		// // Optionally log specific properties
		// if (props.micStream) {
		// 	console.log(`>>>Mic Stream Active Tracks:`, props.micStream.active);
		// }
		// if (props.videoStream) {
		// 	console.log(
		// 		`>>>Video Stream Active Tracks:`,
		// 		props.videoStream.active
		// 	);
		// }
		// if (props.screenStream) {
		// 	console.log(
		// 		`>>>Screen Stream Active Tracks:`,
		// 		props.screenStream.active
		// 	);
		// }

		// const handleMeetingEnd = async () => {
		// 	// console.log('>>>> inside handleMeetingEnd');
		// 	const interviewReportData = await fetchInterviewReportById(params.interviewReportId);
		// 	if (interviewReportData.meetingId) {
		// 		// console.log(
		// 		// 	`>>> inside handleMeetingEnd with if meetingId exists >>> ${interviewReportData.meetingId} just before stopMeeting`
		// 		// );
		// 		stopMeeting(interviewReportData.meetingId);
		// 		// stopMeeting();
		// 	}
		// };

		if (meetingSession) {
			if (props.preferredName === "Admin" && !props.existingMeetingId) {
				// logMeetingEvents();
				exitMeeting();
				return;
			}
			props.preferredName !== "Admin" && !props.isMobile && share();
			const audioVideo = async (meetingSession) => {
				if (props.micStream && props.videoStream) {
					// console.log('props.mic.vid stream true');
					await meetingSession.audioVideo.startAudioInput(props.micStream);
					await meetingSession.audioVideo.startVideoInput(props.videoStream);
				} else {
					if (props.preferredName !== "Admin") {
						const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
						const videoInputs = await meetingSession.audioVideo.listVideoInputDevices();
						await meetingSession.audioVideo.startAudioInput(audioInputs[0].deviceId);
						await meetingSession.audioVideo.startVideoInput(videoInputs[0].deviceId);
					}
				}
				const observer = {
					videoTileDidUpdate: (tileState) => {
						if (!tileState.boundAttendeeId) {
							return;
						}
						if (!(meetingSession === null)) {
							updateTiles(meetingSession);
						}
					},
					connectionDidBecomePoor: () => {
						// props.setSnackbarMessage("Your connection is poor. Attempting to reconnect...");
						// props.setSnackbarOpen(true);

						props.clientLogger("Chime Meeting Observer: connectionDidBecomePoor", {
							source: "Chime Meeting Observer",
						});
					},
					connectionDidSuggestStopVideo: () => {
						props.clientLogger(
							"Chime Meeting Observer: connectionDidSuggestStopVideo. Chime suggested to to stop video to maintain the connection.",
							{
								source: "Chime Meeting Observer",
							},
						);
					},
					connectionDidBecomeGood: () => {
						// props.setSnackbarMessage("Connection has stabilized.");
						// props.setSnackbarOpen(true);
						props.clientLogger("Chime Meeting Observer: connectionDidBecomeGood", {
							source: "Chime Meeting Observer",
						});
						// reestablishStreams();
					},
				};
				const eventObserver = {
					eventDidReceive: (name, attributes) => {
						const { meetingHistory, ...otherAttributes } = attributes;
						switch (name) {
							case "audioInputFailed":
							case "videoInputFailed":
							case "deviceLabelTriggerFailed":
							case "meetingStartFailed":
							case "meetingFailed":
								// Send the last 5 minutes of events.
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: {
								// 			...otherAttributes,
								// 			MeetingHistory: meetingHistory.filter(({ timestampMs }) => {
								// 				return Date.now() - timestampMs < 5 * 60 * 1000;
								// 			}),
								// 		},
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: {
										OtherAttributes: otherAttributes,
										MeetingHistory: meetingHistory.filter(({ timestampMs }) => {
											return Date.now() - timestampMs < 5 * 60 * 1000;
										}),
									},
								});
								break;
							case "sendingAudioFailed":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								if (!modalAlreadyShown) {
									setShowModal(true);
									setModalAlreadyShown(true);
								}
								break;
							case "signalingDropped":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								if (!modalAlreadyShown) {
									setShowModal(true);
									setModalAlreadyShown(true);
								}
								// attemptReconnection();
								break;
							case "meetingEnded":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								cleanup();
								props.setRefresh && props.setRefresh(!props.refresh);
								if (props.preferredName === "secondDevice" && props.isMobile) {
									props.setMeetingEndedForSecondDevice(true);
								}
								break;
							case "meetingStartSucceeded":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	})
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								// props.clientLogger(
								// 	`meetingStartSucceeded: maxVideoTileCount: ${otherAttributes.maxVideoTileCount}`,
								// );
								break;
							default:
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	})
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								break;
						}
					},
				};

				meetingSession.audioVideo.addObserver(observer);
				meetingSession.eventController.addObserver(eventObserver);

				meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeeObserver);
				const audioOutputElement = document.getElementById("audio");
				meetingSession.audioVideo.bindAudioElement(audioOutputElement);
				//ToDo: Mute the audio output if preferredName is 'secondDevice'. This is to mute all playing audio on secondDevice.
				if (props.preferredName === "secondDevice") {
					audioOutputElement.muted = true;
					meetingSession.audioVideo?.realtimeMuteLocalAudio();
				} else {
					audioOutputElement.muted = false;
				}
				meetingSession.audioVideo.start();
				meetingSession.audioVideo.startLocalVideoTile();
				if (props.preferredName === "Admin") {
					if (!props.cameraEnabled) {
						await meetingSession.audioVideo.stopLocalVideoTile();
						setIsVideoOn(false);
					} else {
						const videoInputs = await meetingSession.audioVideo.listVideoInputDevices();

						await meetingSession.audioVideo.startVideoInput(videoInputs[0].deviceId);
						await meetingSession.audioVideo.startLocalVideoTile();
						setIsVideoOn(true);
					}

					if (!props.micEnabled) {
						await meetingSession.audioVideo.realtimeMuteLocalAudio();
						setIsMicOn(false);
					} else {
						const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
						setAudioDevices(audioInputs);
						const defaultDevice = audioInputs[0];
						setSelectedAudioDevice(defaultDevice);
						await meetingSession.audioVideo.startAudioInput(defaultDevice?.deviceId);
						await meetingSession.audioVideo.realtimeUnmuteLocalAudio();
						setIsMicOn(true);
					}
				}
			};
			audioVideo(meetingSession);
			if (props.preferredName === "secondDevice" && props.isMobile) {
				props.setChimeMeetingStartedForSecondDevice(true);
			}
			if (
				props.preferredName === "secondDevice" &&
				props.isSecondaryCameraRequired &&
				!props.isPositionAccurate
			) {
				try {
					setRemSecondsForSd(10);
					captureTimeoutId.current = setTimeout(() => {
						// captureFrame();
						remSecondsIntervalId.current = setInterval(() => {
							setRemSecondsForSd((prevSeconds) => {
								if (prevSeconds === 0) {
									return 0;
								} else {
									return prevSeconds - 1;
								}
							});
						}, 1000);
						captureIntervalId.current = setInterval(() => {
							captureFrame();
						}, 10000);
					}, 6000);
				} catch (error) {
					props.clientLogger(`Error in doMeeting during captureFrame: ${error}`);
				}
			} else if (
				props.isSecondaryCameraRequired
					? props.preferredName === "firstDevice"
					: props.preferredName !== "Admin"
			) {
				try {
					captureTimeoutId.current = setTimeout(() => {
						captureIntervalId.current = setInterval(() => {
							captureFrame();
						}, 13000);
					}, 6000);
				} catch (error) {
					props.clientLogger(`Error in doMeeting during captureFrame: ${error}`);
				}
			}
		} else {
			if (props.preferredName === "Admin" || props.preferredName === "secondDevice") {
				props.existingMeetingId &&
					!meetingAlreadyStarted &&
					doMeeting(
						props.preferredName,
						props.existingMeetingId,
						props.isRecordingEnabledRef?.current,
						props.uniqueExtId,
					);
			} else {
				!meetingAlreadyStarted &&
					doMeeting(
						props.preferredName,
						props.existingMeetingId,
						props.isRecordingEnabledRef?.current,
					);
			}
		}
		return () => {
			if (meetingSession && meetingSession?.audioVideo) {
				meetingSession.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(attendeeObserver);
			}
		};
	}, [meetingSession, props.existingMeetingId]);

	const logger = new ConsoleLogger("ChimeMeetingLogs", LogLevel.OFF);

	const deviceController = new DefaultDeviceController(logger);

	const doMeeting = async (preferredName, existingMeetingId, isRecordingEnabled, uniqueExtId) => {
		if (preferredName === "Admin") preferredName = uniqueExtId;
		// if meeting session already exists, return, already in progress
		if (meetingSession) {
			return;
		}
		try {
			let response;
			if (existingMeetingId) {
				response = await joinMeeting(
					params.interviewReportId,
					existingMeetingId,
					preferredName,
					props.candidateName,
				);
			} else {
				response = await createMeeting(
					params.interviewReportId,
					props.candidateName,
					isRecordingEnabled,
				);
			}
			props.clientLogger(existingMeetingId ? "Chime Meeting Joined" : "Chime Meeting Started");
			setMeetingObj(response.Meeting);
			setAttendeeObj(response.Attendee);

			const configuration = new MeetingSessionConfiguration(response.Meeting, response.Attendee);
			setMeetingSession(new DefaultMeetingSession(configuration, logger, deviceController));
			setMeetingAlreadyStarted(true);
			props.setChimeStarted?.(true);
		} catch (err) {
			props.clientLogger(`Error in doMeeting: ${err}`);
			if (
				typeof err?.response?.data?.error === "string" &&
				err?.response?.data?.error.includes("not found")
			) {
				props.clientLogger(`Error in doMeeting: ${err?.response?.data?.error}`);
				props.setIsMeetingActive && props.setIsMeetingActive(false);
			}
		}
	};

	const updateTiles = (meetingSession) => {
		try {
			const tiles = meetingSession?.audioVideo?.getAllVideoTiles() || [];
			tiles.forEach((tile) => {
				try {
					let tileId = tile.tileState.tileId;
					let divElement = document.getElementById(`div-${tileId}`);
					let divElementName = document.getElementsByName(
						`div-${tile.tileState.boundAttendeeId}`,
					)[0];
					if (!divElement && !divElementName) {
						divElement = document.createElement("div");
						divElement.id = `div-${tileId}`;
						divElement.classList.add("video-container");
						divElement.setAttribute("name", `div-${tile.tileState.boundAttendeeId}`);
						divElement.style.display = "inline-block";
						divElement.style.padding = "5px";

						// ToDo: remove LET when it is initialized elsewhere
						let videoElement = document.createElement("video");
						videoElement.id = `video-${tileId}`;
						videoElement.setAttribute("name", `video-${tile.tileState.boundAttendeeId}`);
						videoElement.controls = false;
						// videoElement.setAttribute('controls', false);

						// Disable right-click context menu on the video element
						videoElement.addEventListener("contextmenu", (event) => {
							event.preventDefault();
						});

						// Disable Picture-in-Picture
						videoElement.disablePictureInPicture = true;
						videoElement.setAttribute("disablePictureInPicture", "");

						// if (props.isSecondDevice) {
						// 	videoElement.style.transform = 'rotateZ(90deg)';
						// }

						if (
							props.preferredName !== "Admin" &&
							tile.tileState.boundAttendeeId === attendeeObj?.AttendeeId &&
							tile.tileState.boundAttendeeId.indexOf("content") === -1
						) {
							if (
								props.isSecondaryCameraRequired ? props.preferredName === "firstDevice" : true
							) {
								videoElement.setAttribute("first-device", "true");
							} else if (
								isMobile &&
								props.isSecondaryCameraRequired &&
								props.preferredName === "secondDevice"
							) {
								videoElement.setAttribute("second-device", "true");
								videoElement.classList.add("video-element-second-device");
							}
						} else if (isMobile) return;
						// p element if need to display name of attendee
						try {
							divElement.append(videoElement);
						} catch (error) {
							props.clientLogger(`Error in divElement.append(videoElement): ${error}`);
						}

						videoElement.style.background = "black";
						videoElement.style.objectFit = "contain";

						if (props.preferredName === "Admin") {
							let nameLabel = document.createElement("div");
							nameLabel.classList.add("name-label");
							const isContentShare = tile.tileState.boundAttendeeId.indexOf("content") !== -1;
							let attendeeName = tile.tileState.boundExternalUserId.split("|")[0];
							if (isContentShare) {
								attendeeName = attendeeName.split("#")[0] + " (Screen)";
							} else if (attendeeName.includes("#candidate")) {
								attendeeName = attendeeName.split("#")[0] + " (Candidate)";
							} else if (props.uniqueExtId === tile.tileState.boundExternalUserId) {
								attendeeName += " (You)";
							} else attendeeName = attendeeName.split("#")[0];
							nameLabel.textContent = attendeeName;

							nameLabel.style.position = "absolute";
							nameLabel.style.bottom = "10px";
							nameLabel.style.left = "10px";
							nameLabel.style.transform = "none";
							nameLabel.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
							nameLabel.style.color = "white";
							nameLabel.style.padding = "4px 10px";
							nameLabel.style.borderRadius = "4px";
							nameLabel.style.fontSize = "12px";
							nameLabel.style.backdropFilter = "blur(8px)";
							nameLabel.style.WebkitBackdropFilter = "blur(8px)";
							nameLabel.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
							nameLabel.style.textShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";

							divElement.appendChild(nameLabel);
						}

						if (props.preferredName === "Admin") {
							// if (props.vidH && props.vidW) {
							// 	videoElement.style.height = props.vidH;
							// 	videoElement.style.width = props.vidW;
							// }
							videoElement.style.height = "100%";
							videoElement.style.width = "100%";
							// videoElement.style.objectFit = 'contain';
							// videoElement.style.background = 'black';
							let adminVideoList = document.getElementById("video-list");
							adminVideoList
								? (adminVideoList.id = "admin-video-list")
								: (adminVideoList = document.getElementById("admin-video-list"));
							divElement.classList.add("admin-video-container");
							divElement.style.position = "relative";
							videoElement.addEventListener("loadedmetadata", () => {
								const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
								// Set the height of the container based on the width and the aspect ratio of the video
								divElement.style.width = "100%"; // or any specific width you want
								divElement.style.height = `${divElement.clientWidth / aspectRatio}px`;
							});
							// adminVideoList.appendChild(divElement);
							try {
								adminVideoList.appendChild(divElement);
							} catch (error) {
								props.clientLogger(
									`Error in adminVideoList.appendChild(divElement): ${error}`,
								);
							}
							const fullscreenButton = document.createElement("button");
							fullscreenButton.innerHTML = '<i class="fas fa-expand"></i>';
							fullscreenButton.classList.add("fullscreen-button");

							fullscreenButton.onclick = function () {
								if (!document.fullscreenElement) {
									divElement
										.requestFullscreen()
										.then(() => {
											fullscreenButton.innerHTML = '<i class="fas fa-compress"></i>';
										})
										.catch((err) => {
											console.error(
												`Error attempting to enable full-screen mode: ${err.message}`,
											);
										});
								} else {
									if (document.exitFullscreen) {
										document.exitFullscreen().then(() => {
											fullscreenButton.innerHTML = '<i class="fas fa-expand"></i>';
											// Optionally, revert the button's position/style adjustments here
										});
									}
								}
							};

							// Append the fullscreen icon to the divElement (video container)
							// divElement.append(fullscreenButton);
							try {
								divElement.append(fullscreenButton);
							} catch (error) {
								props.clientLogger(`Error in divElement.append(fullscreenButton): ${error}`);
							}
							// const fullscreenIcon = document.createElement('i');
							// fullscreenIcon.className = 'fas fa-expand fullscreen-icon';

							// fullscreenIcon.setAttribute('role', 'button');
							// fullscreenIcon.setAttribute(
							// 	'aria-label',
							// 	'Toggle Fullscreen'
							// ); // Accessibility: Descriptive label for screen readers

							// fullscreenIcon.onclick = function () {
							// 	if (!document.fullscreenElement) {
							// 		divElement
							// 			.requestFullscreen()
							// 			.then(() => {
							// 				fullscreenIcon.className =
							// 					'fas fa-compress fullscreen-icon'; // Change icon to 'compress' when in fullscreen
							// 			})
							// 			.catch((err) => {
							// 				console.error(
							// 					`Error attempting to enable full-screen mode: ${err.message}`
							// 				);
							// 			});
							// 	} else {
							// 		if (document.exitFullscreen) {
							// 			document.exitFullscreen().then(() => {
							// 				fullscreenIcon.className =
							// 					'fas fa-expand fullscreen-icon'; // Change icon back to 'expand' when exiting fullscreen
							// 			});
							// 		}
							// 	}
							// };

							// // Append the fullscreen icon to the divElement (video container)
							// divElement.append(fullscreenIcon);
						}
						// props.preferredName === "Admin"
						// 	? document.getElementById("admin-video-list").append(divElement)
						// 	: document.getElementById("video-list").append(divElement);
						try {
							props.preferredName === "Admin"
								? document.getElementById("admin-video-list").append(divElement)
								: document.getElementById("video-list").append(divElement);
						} catch (error) {
							props.clientLogger(`Error in appending divElement to video-list: ${error}`);
						}
						//ToDO: This actually mutes the input from the device and NOT the incoming audio.
						// if (props.preferredName === 'secondDevice') {
						// 	meetingSession.audioVideo.realTimeMuteLocalAudio();
						// }

						meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
					} else {
						// divElementName &&
						// 	console.log(
						// 		`divElementName exists: ${JSON.stringify(
						// 			divElementName
						// 		)}`
						// 	);
						// divElement && console.log(`divElement exists: ${divElement}`);
						let videoElement = document.getElementsByName(
							`video-${tile.tileState.boundAttendeeId}`,
						)[0];
						meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
						// videoElement &&
						// 	console.log(
						// 		`videoElement exists: ${JSON.stringify(videoElement)}`
						// 	);

						if (props.preferredName === "Admin" && divElement) {
							let nameLabel = divElement.querySelector(".name-label");
							if (nameLabel) {
								const isContentShare =
									tile.tileState.boundAttendeeId.indexOf("content") !== -1;
								let attendeeName = tile.tileState.boundExternalUserId.split("|")[0];
								if (isContentShare) {
									attendeeName = attendeeName.split("#")[0] + " (Screen)";
								} else if (attendeeName.includes("#candidate")) {
									attendeeName = attendeeName.split("#")[0] + " (Candidate)";
								} else if (props.uniqueExtId === tile.tileState.boundExternalUserId) {
									attendeeName += " (You)";
								} else attendeeName = attendeeName.split("#")[0];
								nameLabel.textContent = attendeeName;

								// Update styling to match new tiles
								nameLabel.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
								nameLabel.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
								nameLabel.style.textShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";
							}
						}
					}
				} catch (error) {
					props.clientLogger(`Error updating individual tile: ${error}`);
				}
			});
		} catch (error) {
			props.clientLogger(`Error in updateTiles: ${error}`);
		}
	};

	const attendeeObserver = async (attendeeId, present, externalUserId, dropped, posInFrame) => {
		try {
			let attendeeUsername = externalUserId?.split("|")?.[0] || "Unknown";

			if (attendeeUsername?.startsWith("aws:")) {
				return;
			}

			if (present && attendeeId?.includes("#content")) {
				attendeeUsername += " (screen)";
				return;
			}

			if (attendeeUsername?.includes("#candidate")) {
				attendeeUsername = attendeeUsername.split("#")?.[0] + " (Candidate)";
			}
			if (props.uniqueExtId === externalUserId) {
				attendeeUsername += " (You)";
			}

			if (meetingSession?.audioVideo) {
				if (present) {
					try {
						// Subscribe to volume indicator
						await subscribeToAttendeeVolume(attendeeId);

						// Initialize attendee state
						setAttendees((prev) => {
							const existingAttendee = prev.get(attendeeId) || {};
							return new Map(prev).set(attendeeId, {
								...existingAttendee,
								id: attendeeId,
								name: attendeeUsername,
								isSpeaking: existingAttendee?.isSpeaking ?? false,
								isMuted: existingAttendee?.isMuted ?? false,
							});
						});
					} catch (error) {
						props.clientLogger(`Error handling present attendee: ${error}`);
					}
				} else {
					try {
						// Remove attendee
						const elements = document.getElementsByName(`div-${attendeeId}`);
						elements[0]?.remove();

						setAttendees((prev) => {
							const newMap = new Map(prev);
							newMap.delete(attendeeId);
							return newMap;
						});

						meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId);
					} catch (error) {
						props.clientLogger(`Error handling absent attendee: ${error}`);
					}
				}
			}
		} catch (error) {
			props.clientLogger(`Error in attendeeObserver: ${error}`);
		}
	};

	// Refresh attendee list in UI view
	// const refreshAttendeesDisplay = () => {};

	const stopMeeting = async (fetchedMeetingId) => {
		const idToUse = fetchedMeetingId || meetingObj?.MeetingId;
		try {
			await endMeeting(idToUse, props.candidateName);
			props.clientLogger("Chime Meeting Ended");
			cleanup();
		} catch (err) {
			props.clientLogger(`Error in stopMeeting: ${err}`);
		}
	};
	// Leave meeting
	const exitMeeting = async () => {
		try {
			await deleteAttendee(meetingObj?.MeetingId, attendeeObj?.AttendeeId, params.interviewReportId);
			props.clientLogger(`Chime Meeting Exited by preferredName: ${props?.preferredName}`);
			cleanup();
		} catch (error) {
			props.clientLogger(`Error in exitMeeting: ${error}`);
		}
	};

	// Toggle Screen Share
	const share = async () => {
		try {
			if (!meetingSession?.audioVideo) {
				throw new Error("No active meeting session");
			}

			if (isScreenShared) {
				await meetingSession.audioVideo.stopContentShare();
				setIsScreenShared(false);
			} else {
				if (props.screenStream) {
					await meetingSession.audioVideo.startContentShare(props.screenStream);
				} else {
					await meetingSession.audioVideo.startContentShareFromScreenCapture();
				}
				setIsScreenShared(true);
			}
		} catch (error) {
			props.clientLogger(`Error in share: ${error}`);
		}
	};

	const cleanup = () => {
		props.setChimeStarted?.(false);
		try {
			// meetingSession.audioVideo.stopVideoInput();
			// meetingSession.audioVideo.stopAudioInput();
			meetingSession.audioVideo.stop();
		} catch (error) {
			props.clientLogger(`Error in cleanup in meetingSession.audioVideo.stop(): ${error}`);
		}
		try {
			meetingSession.deviceController.destroy();
		} catch (error) {
			props.clientLogger(`Error in cleanup in meetingSession.deviceController.destroy(): ${error}`);
		}
		setMeetingSession(null);
		// meetingSession = null;
		document.getElementById("video-list")?.replaceChildren();
		setAttendees(new Map());
		// Clean up the loggers so they don't keep their `onload` listeners around.
		// if (meetingEventPOSTLogger) {
		// 	meetingEventPOSTLogger
		// 		.destroy()
		// 		.then(() => {
		// 			setMeetingEventPOSTLogger(null); // Reset the logger state after destruction
		// 		})
		// 		.catch((error) => {
		// 			console.error("Failed to destroy the logger:", error);
		// 		});
		// }
		// refreshAttendeesDisplay();
	};

	const captureFrame = () => {
		let videoElement;
		if (props.isSecondaryCameraRequired ? props.preferredName === "firstDevice" : true) {
			videoElement = document.querySelector('video[first-device="true"]');
			if (!props?.activeSessionRef.current) {
				props.clientLogger("No active session. Clearing timers.");
				clearCaptureTimers();
				return;
			} else if (!videoElement) {
				props.clientLogger("No video element found. Clearing timers and halting interview.");
				clearCaptureTimers();
				props?.setFailedFaceDetectCount(10);
				return;
			}
		} else if (isMobile && props.isSecondaryCameraRequired && props.preferredName === "secondDevice") {
			videoElement = document.querySelector('video[second-device="true"]');
			if (!videoElement) {
				props.clientLogger("No video element found. Clearing timers and halting interview.");
				clearCaptureTimers();
				props?.setFailedFaceDetectCount(10);
				return;
			}
		}

		const canvas = document.createElement("canvas");
		canvas.width = videoElement.videoWidth;
		canvas.height = videoElement.videoHeight;
		const ctx = canvas.getContext("2d");

		// Flip the image horizontally
		ctx.translate(canvas.width, 0);
		ctx.scale(-1, 1);

		ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

		// Convert canvas to blob
		canvas.toBlob(
			async (blob) => {
				if (props.isSecondaryCameraRequired ? props.preferredName === "firstDevice" : true) {
					try {
						if (!blob || blob?.size === 0) {
							props.clientLogger("Generated blob is empty or invalid. Halting interview.");
							clearCaptureTimers();
							props?.setFailedFaceDetectCount(10);
							return;
						}
						const fd = await faceDetection(
							blob,
							params.interviewReportId,
							new Date().toISOString(),
						);
						if (fd?.message === 1) {
							props?.setFailedFaceDetectCount(0);
						} else if (fd?.message === "skipped") {
							props.clientLogger("Face Detection Skipped. Clearing timers.");
							clearCaptureTimers();
						} else {
							props.clientLogger(
								`No Face or Multiple Faces Detected in Frame. Count: ${fd?.message}`,
							);
							props?.setFailedFaceDetectCount((prev) => {
								if (prev === 9) {
									clearCaptureTimers();
									props.clientLogger("Face Detection Failed 10 times. Halting interview.");
								}
								return prev + 1;
							});
						}
					} catch (error) {
						props.clientLogger(
							`Error in faceDetection. Error Message ${error}, Error Response Data: ${JSON.stringify(error?.response?.data)}`,
						);
						if (error?.response?.data?.noFile) {
							props.clientLogger("No File was sent to the server. Halting interview.");
							clearCaptureTimers();
							props?.setFailedFaceDetectCount(10);
						}
					}
				} else if (
					isMobile &&
					props.isSecondaryCameraRequired &&
					props.preferredName === "secondDevice" &&
					!props.isPositionAccurate
				) {
					try {
						if (!blob || blob?.size === 0) {
							throw new Error("Generated blob is empty or invalid. Will keep checking.");
						}
						const gv = await gptVision(blob, params.interviewReportId, new Date().toISOString());
						const verdict = gv?.verdict === "true" || gv?.verdict === true;
						if (verdict) {
							await updateSecondDevice("true", "pos", params.interviewReportId);
							props.setIsPositionAccurate(true);
							setRemSecondsForSd(null);
							clearCaptureTimers();
						} else {
							setRemSecondsForSd(10);
						}
					} catch (error) {
						props.clientLogger(`Error in gptVision: ${error}`);
						setRemSecondsForSd(10);
					}
				}
			},
			"image/jpeg",
			0.5,
		); // JPEG format with 50% quality
	};

	return (
		<Box id="container">
			{/* Admin UI Controls - Shown only for admin users */}
			{props.preferredName === "Admin" && (
				<AdminUI
					candidateName={props.candidateName}
					openingTitle={props.openingTitle}
					isVideoOn={isVideoOn}
					handleVideo={handleVideo}
					isMicOn={isMicOn}
					handleMic={handleMic}
					isScreenShared={isScreenShared}
					share={share}
					isTabAudioMuted={isTabAudioMuted}
					toggleMute={toggleMute}
				/>
			)}

			{/* Secondary Camera Position Check UI - Mobile Only */}
			{props.isMobile &&
				props.isSecondaryCameraRequired &&
				props.preferredName === "secondDevice" &&
				props?.chimeMeetingStartedForSecondDevice && (
					<Box className="mb-2">
						{remSecondsForSd === 0 ? (
							<Typography
								variant="subtitle1"
								className="text-center font-semibold text-blue-500"
							>
								Checking...{<i className="fas fa-circle-notch fa-spin"></i>}
							</Typography>
						) : (
							remSecondsForSd > 0 && (
								<Typography
									variant="subtitle1"
									className="text-center font-semibold text-blue-500"
								>
									Checking your device position in{" "}
									<span className="text-red-500">{remSecondsForSd}</span> seconds
								</Typography>
							)
						)}
					</Box>
				)}

			<audio id="audio"></audio>

			{/* Main Content Area - Different layouts for Admin vs Non-Admin */}
			{props.preferredName === "Admin" ? (
				// Admin Layout with Video Tiles and Side Panel
				<Box className="flex flex-col lg:flex-row w-full">
					{/* Mobile: Side Panel appears above video tiles */}
					{isMobile && (
						<Box className="w-full mb-4">
							<SidePanel
								attendees={Array.from(attendees.values())}
								audioDevices={audioDevices}
								selectedDevice={selectedAudioDevice}
								onDeviceChange={handleAudioDeviceChange}
								shouldAddPositionSwitch={props?.shouldAddPositionSwitch || false}
								lastPositionStatus={props?.lastPositionStatus || false}
								clientLogger={props?.clientLogger}
							/>
						</Box>
					)}

					{/* Video Tiles Section */}
					<Box id="admin-video-list" className={`w-full ${!isMobile ? "lg:w-3/4 lg:mr-4" : ""}`}>
						{/* Video tiles will be dynamically added here by the updateTiles function */}
					</Box>

					{/* Desktop: Side Panel appears on the right */}
					{!isMobile && (
						<Box className="lg:w-1/4">
							<SidePanel
								attendees={Array.from(attendees.values())}
								audioDevices={audioDevices}
								selectedDevice={selectedAudioDevice}
								onDeviceChange={handleAudioDeviceChange}
								shouldAddPositionSwitch={props?.shouldAddPositionSwitch || false}
								lastPositionStatus={props?.lastPositionStatus || false}
								clientLogger={props?.clientLogger}
							/>
						</Box>
					)}
				</Box>
			) : (
				// Non-Admin Layout - Full width video tiles only
				<Box id="video-list" className="w-full">
					{/* Video tiles will be dynamically added here by the updateTiles function */}
				</Box>
			)}

			{/* Network Modal - Shown for non-admin users when network issues occur */}
			{props.preferredName !== "Admin" && showModal && (
				<NetworkModal showModal={showModal} setShowModal={setShowModal} />
			)}
		</Box>
	);
};

export default VideoTiles;
