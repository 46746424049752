import {
	Box,
	Rating,
	Button,
	CircularProgress,
	Typography,
	Skeleton,
	Stack,
	ThemeProvider,
	useTheme,
	Tooltip,
	IconButton,
} from "@mui/material";
import { JsonView, allExpanded, darkStyles, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import poster from "./../assets/zinterview-logo-white.png";
import logo from "../assets/zi-favicon.png";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { fetchInterviewReportById, getEvaluationForInterivew } from "../utilities/interviewReportsApi";
import { fetchOpeningById } from "../utilities/openingsApi";
import { getFormattedDateTime2 } from "../utilities/utilityFunctions";
import { getCapturedFrames } from "../utilities/awsApi";
import InterviewMessages from "./InterviewMessages";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
	BigPlayButton,
	ControlBar,
	ForwardControl,
	LoadingSpinner,
	Player,
	ReplayControl,
} from "video-react";
import "../../node_modules/video-react/dist/video-react.css";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Markdown from "react-markdown";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DownloadEvaluationReportMenu } from "../components/interview-report";
import { NetworkImage } from "../components/interview-report/network-image";
import { DevOptions } from "../components/interview-report/evaluate-form";
import FaceDetectionFrames from "../components/interview-report/FaceDetectionFrames";
import { useGetCheatingScoreForCandidateQuery } from "../features/api/apiSlice";
import { FlagComponent } from "../components/grid/columnRenderer/CheatingScoreRenderer";

import InterviewReportModal from "../components/interview-report/interview-report-config-modal";
import { Settings } from "@mui/icons-material";
import axiosInstance from "../utilities/axiosInstance";

const tabs = {
	EVALUATION: "evaluation",
	RECORDING: "recording",
	INTERVIEW_MESSAGES: "interviewMessages",
	UNSCRIPTED_ELSA: "unscriptedElsa",
	AZURE_PRONUNCIATION: "azurePronunciation",
	FACE_DETECTION_FRAMES: "faceDetectionFrames",
};

const InterviewReport = () => {
	const { user } = useSelector((state) => state.auth);
	const theme = useTheme();
	const dispatch = useDispatch();
	const params = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [report, setReport] = useState(null);
	const [isLoadingReport, setIsLoadingReport] = useState(false);
	const [showGetCustomEvalBtn, setShowGetCustomEvalBtn] = useState(false);
	const [openingDetails, setOpeningDetails] = useState(null);
	const [isMakingNewEvalRequest, setIsMakingNewEvalRequest] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [faceDetectionFrames, setFaceDetectionFrames] = useState([]);
	const [currentTab, setCurrentTab] = useState(tabs.EVALUATION);
	const [expandedStates, setExpandedStates] = useState({});
	const unscripted = localStorage.getItem("unscripted");

	// Toggle the modal open/close state
	const handleToggleModal = () => {
		setIsModalOpen((prev) => !prev);
	};

	const [configData, setConfigData] = useState(null);
	// Modal Configuration State
	const [isModalOpen, setIsModalOpen] = useState(false);

	// Handle Puppeteer Request
	const [isPuppeteer, setIsPuppeteer] = useState(false);
	useEffect(() => {
		const source = searchParams.get("source");
		if (source === "puppeteer") {
			console.log("This request was made by Puppeteer.");
			// Perform any specific logic for Puppeteer requests here
			setIsPuppeteer(true);
		}
	}, [searchParams]);

	const MAX_CHARS = 150;
	const toggleExpanded = (id) => {
		setExpandedStates((prevStates) => ({
			...prevStates,
			[id]: !prevStates[id],
		}));
	};

	function CircularProgressWithLabel(props) {
		return (
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress size={40} variant="determinate" {...props} />
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="subtitle1" component="div" sx={{ fontWeight: 500 }}>
						{`${Math.round(props.value)}`}
					</Typography>
				</Box>
			</Box>
		);
	}

	CircularProgressWithLabel.propTypes = {
		value: PropTypes.number.isRequired,
	};

	useEffect(() => {
		if (
			user &&
			openingDetails &&
			user?.organizationId !== openingDetails?.organizationId &&
			user.role !== "1096"
		) {
			navigate("/admin");
		}
	}, [user, openingDetails, navigate, dispatch]);

	useEffect(() => {
		async function showEvaluationDetails() {
			if (user && params.interviewReportId) {
				const report = await fetchInterviewReportById(params.interviewReportId);
				if (report) {
					setReport(report);
					try {
						const resp = await getCapturedFrames(report?._id);
						setFaceDetectionFrames(resp?.frames);
					} catch (error) {
						console.log(error);
					}
				}
			}
		}

		async function getOpeningDetails() {
			const openingId = searchParams.get("openingId");
			if (user && openingId) {
				const response = await fetchOpeningById(openingId);
				if (response) {
					setOpeningDetails(response);
				}
				return response;
			}
		}

		async function fetchRequiredDetails() {
			try {
				setIsLoadingReport(true);
				const openingDetails = await getOpeningDetails();
				if (
					openingDetails &&
					user &&
					openingDetails?.organizationId !== user?.organizationId &&
					user.role != "1096"
				) {
					throw new Error("Unauthorized");
				} else {
					await showEvaluationDetails();
				}
				setIsLoadingReport(false);
			} catch (e) {
				setIsLoadingReport(false);
				toast.warn(e.message);
			}
		}
		fetchRequiredDetails();
	}, []);

	useEffect(() => {
		const activeCustomEval = window.localStorage.getItem("activate-custom-eval");
		if (activeCustomEval === "ACTIVATE") {
			setShowGetCustomEvalBtn(true);
		}
	}, []);

	useEffect(() => {
		const fetchConfig = async () => {
			try {
				// Fetch configuration
				const findConfig = await axiosInstance.get(
					`/api/evaluationReports/config/${user.organizationId}`,
				);
				const savedConfig = findConfig?.data?.data;

				if (savedConfig) {
					setConfigData(savedConfig);
				} else {
					// There is no saved config at this point, create a new one
					const initialFields = {
						name: false,
						email: false,
						phone: false,
						trustScore: false,
						interviewDate: false,
						interviewEndDate: false,
						candidatePhoto: false,
						logoUrl: null,
						questionsAndEvaluation: false,
						cheatingScore: false,
					};

					const response = await axiosInstance.post(
						`/api/evaluationReports/hide-report-fields/${user.organizationId}`,
						{ config: initialFields },
					);

					// ✅ Check if the response status is 200 or 201 before updating state
					if (response.status === 200 || response.status === 201) {
						setConfigData(initialFields);
					}
				}
			} catch (error) {
				console.error("Error fetching config data:", error);
			}
		};

		fetchConfig();
	}, [user.organizationId]);

	const getProgressColor = (value) => {
		if (value >= 70) return "green";
		if (value >= 50) return "orange"; // No need to specify upper bound here
		return "red";
	};

	async function completeInterviewAndGetEvaluation(data = {}) {
		try {
			const openingId = searchParams.get("openingId");
			const interviewReportId = params.interviewReportId;
			if (openingId && interviewReportId) {
				setIsMakingNewEvalRequest(true);
				const { customEvaluationPrompt } = data;
				const evaluation = await getEvaluationForInterivew(
					openingId,
					interviewReportId,
					customEvaluationPrompt,
				);
				setReport((prev) => ({ ...prev, evaluation: evaluation }));

				setIsMakingNewEvalRequest(false);
			} else {
				console.log("missing openingId or interviewReportId");
			}
		} catch (e) {
			toast.warn(e.message);
			setIsMakingNewEvalRequest(false);
		}
	}

	const Evaluation = () => {
		const { evaluation = "", communicationEvaluation = "", interviewCompleted } = report;
		const paperObjs = [];
		const totalMessages = report?.messages;
		let parsedEvaluation = evaluation;
		let parsedJSON;
		let availableKey;
		let evaluation_report;
		let rating_for_each_skill;
		try {
			if (evaluation.trim() !== "") {
				parsedJSON = JSON.parse(evaluation);
				const { interview_questions_evaluation, interview_quetsions_evaluation, verdict } =
					parsedJSON;
				evaluation_report = parsedJSON.evaluation_report;
				availableKey = interview_quetsions_evaluation
					? interview_quetsions_evaluation
					: interview_questions_evaluation;
				rating_for_each_skill =
					parsedJSON.ratings_for_skills || evaluation_report?.rating_for_each_skill;
				return !interviewCompleted ? (
					<Typography variant="body1" textAlign={"center"}>
						The Interview is On Going
					</Typography>
				) : isMakingNewEvalRequest ? (
					<CircularProgress />
				) : evaluation.length > 0 ? (
					<Box className="flex flex-col-reverse gap-4 md:flex-row text-sm">
						{(!configData || !configData?.questionsAndEvaluation) && (
							<Box className="flex-1 overflow-hidden">
								<h3
									sx={{ breakInside: "avoid-page" }}
									className="text-xl font-bold mb-4 text-purple-900"
								>
									Questions and Evaluations
								</h3>
								{availableKey.map((questionObj, i) => {
									const hasPositivesAndNegatives = Boolean(
										questionObj?.positives || questionObj?.negatives,
									);
									return (
										<Box
											key={i}
											className="odd:bg-gray-50 even:bg-gray-100 rounded-lg p-4 mb-2"
										>
											{Object.entries(questionObj).map((property, index) => {
												if (property[0] === "indexes_of_the_answer") {
													let answerGivenForTheCurrentQuestion = "";
													if (
														Array.isArray(property[1]) &&
														property[1].length > 0
													) {
														property[1].forEach((answerIndex) => {
															if (
																totalMessages[answerIndex] &&
																totalMessages[answerIndex]?.content
															) {
																answerGivenForTheCurrentQuestion +=
																	totalMessages[answerIndex].content + "\n";
															}
														});
														const codeStringIndex =
															answerGivenForTheCurrentQuestion.indexOf("```");
														const codeString =
															codeStringIndex !== -1
																? answerGivenForTheCurrentQuestion.substring(
																		codeStringIndex + 3,
																		answerGivenForTheCurrentQuestion.length -
																			4,
																	)
																: "";
														const responseMessage =
															codeStringIndex === -1
																? answerGivenForTheCurrentQuestion
																: answerGivenForTheCurrentQuestion.substring(
																		0,
																		codeStringIndex - 1,
																	);
														const isCurrentExpanded = expandedStates[i] || false;
														const isTextTooLong =
															answerGivenForTheCurrentQuestion.length >
															MAX_CHARS;
														const textToShow =
															isCurrentExpanded ||
															!isTextTooLong ||
															isDownloading
																? responseMessage
																: responseMessage.substring(0, MAX_CHARS) +
																	"...";
														return (
															<Box className="flex flex-col">
																<Typography
																	variant="p"
																	sx={{ breakInside: "avoid-page" }}
																	className="font-semibold mb-2 whitespace-pre-line text-left"
																	key={index}
																>
																	Candidate's Answer
																	{property[0] !== "question" && ":"}
																</Typography>
																<Typography
																	variant="p"
																	sx={{ breakInside: "avoid-page" }}
																	className="whitespace-pre-line mb-2 text-left"
																>
																	{textToShow}
																	{isTextTooLong &&
																		!isCurrentExpanded &&
																		!isDownloading && (
																			<Typography
																				variant="p"
																				sx={{
																					breakInside: "avoid-page",
																				}}
																				className="text-gray-600 hover:text-purple-800 font-semibold cursor-pointer rounded p-[0.1rem]"
																				onClick={() =>
																					toggleExpanded(i)
																				}
																			>
																				Read more
																			</Typography>
																		)}
																</Typography>
																{codeString && (
																	<Box margin={"1rem"}>
																		{codeStringIndex != -1 &&
																			codeString.length > 0 && (
																				<div>
																					<div
																						style={{
																							display: "flex",
																							justifyContent:
																								"space-between",
																							alignItems:
																								"center",
																							backgroundColor:
																								"black",
																							borderRadius:
																								"10px 10px 0 0",
																							paddingInline:
																								"10px",
																						}}
																					>
																						<span
																							style={{
																								color: "white",
																								padding:
																									"5px 3px",
																							}}
																						>
																							Code
																						</span>
																					</div>
																					<SyntaxHighlighter
																						language={
																							"javascript"
																						}
																						style={monoBlue}
																						customStyle={{
																							padding: "2rem",
																							borderRadius:
																								"0 0 10px 10px",
																						}}
																					>
																						{codeString.trim()}
																					</SyntaxHighlighter>
																				</div>
																			)}
																	</Box>
																)}
																{isTextTooLong &&
																	isCurrentExpanded &&
																	!isDownloading && (
																		<Typography
																			variant="p"
																			sx={{ breakInside: "avoid-page" }}
																			className="text-gray-600 hover:text-purple-800 font-semibold cursor-pointer rounded p-[0.1rem] mb-2"
																			onClick={() => toggleExpanded(i)}
																		>
																			Read less
																		</Typography>
																	)}
															</Box>
														);
													} else {
														return (
															<Box className="flex flex-col">
																<Typography
																	variant="p"
																	sx={{ breakInside: "avoid-page" }}
																	className="font-semibold mb-2 text-left"
																	key={index}
																>
																	Candidate's Answer
																	{property[0] !== "question" && ":"}
																</Typography>
																<Typography
																	variant="p"
																	sx={{ breakInside: "avoid-page" }}
																	className="mb-2 text-left"
																>
																	{answerGivenForTheCurrentQuestion}
																</Typography>
															</Box>
														);
													}
												} else if (
													property[0] === "positives" ||
													property[0] === "negatives"
												) {
													return (
														<React.Fragment key={property[0]}>
															{property[0] === "positives" && (
																<Typography
																	sx={{ breakInside: "avoid-page" }}
																	variant="p"
																	className="font-semibold mb-2 text-left"
																>
																	{"Evaluation"}
																</Typography>
															)}
															<RenderStrengthsAndWeaknesses
																items={property[1]}
																label={property[0]}
																needsBottomPadding={
																	property[0] === "positives"
																}
																needsPaddingForHeading={false}
															/>
														</React.Fragment>
													);
												} else {
													return (
														<>
															{((property[0] === "evaluation" &&
																!hasPositivesAndNegatives) ||
																property[0] !== "evaluation") && (
																<Box className="flex flex-col">
																	<Typography
																		variant="p"
																		sx={{ breakInside: "avoid-page" }}
																		className="font-semibold mb-2 text-left"
																		key={index}
																	>
																		{snakeCaseToHeaders(property[0])}
																		{property[0] === "question" &&
																			` ${i + 1}`}
																		{property[0] !== "question" && ":"}
																	</Typography>
																	<Typography
																		variant="p"
																		className="text-left mb-2"
																		sx={{ breakInside: "avoid-page" }}
																	>
																		{property[0] === "score_in_percentage"
																			? `${property[1]}%`
																			: property[1]}
																	</Typography>
																</Box>
															)}
														</>
													);
												}
											})}
										</Box>
									);
								})}
							</Box>
						)}

						<Box className="flex-1 text-sm">
							<h3 className="text-xl font-bold mb-4 text-purple-900">Overall Summary</h3>

							<Box className="bg-gray-50 rounded-lg p-4">
								{evaluation_report &&
									Object.entries(evaluation_report).map(([key, value], index) => {
										if (["strengths", "weaknesses"].includes(key)) {
											return (
												<RenderStrengthsAndWeaknesses
													key={key}
													items={value}
													label={key}
												/>
											);
										}
										if (key === "rating_for_each_skill") {
											// Skip or handle 'rating_for_each_skill' differently
											return null;
										}

										if (typeof value === "object" && value !== null) {
											// Handle the case where value is an object
											// You might need to adjust this part to fit the structure of your data
										}

										// Handle the case where value is not an object (e.g., a string or a number)
										// Render them in a Box with Typography as in your example
										return (
											<Box className="flex flex-col">
												{key === "overall_score_in_percentage" ? (
													<Box className="mb-2 p-4 pb-0 flex flex-row items-center gap-[10px]">
														<Typography
															sx={{ breakInside: "avoid-page" }}
															variant="p"
														>
															Score:
														</Typography>
														<Typography
															variant="p"
															sx={{ breakInside: "avoid-page" }}
															className="mt-1 text-xl font-bold flex flex-row items-center gap-[5px]"
														>
															<CircularProgressWithLabel
																color={getProgressColor(value)}
																value={value}
															/>{" "}
															%
														</Typography>
													</Box>
												) : (
													<Box key={index} className="mb-2 px-4">
														<Typography
															variant="p"
															sx={{ breakInside: "avoid-page" }}
															className="font-bold"
														>
															{snakeCaseToHeaders(key)}:
														</Typography>
														<Typography
															variant="p"
															sx={{ breakInside: "avoid-page" }}
														>{` ${value}`}</Typography>
													</Box>
												)}
											</Box>
										);
									})}
							</Box>
							{rating_for_each_skill && Array.isArray(rating_for_each_skill) ? (
								<RatingsComponent ratings={rating_for_each_skill} />
							) : null}
						</Box>
					</Box>
				) : (
					<Button
						variant="contained"
						onClick={(e) => {
							completeInterviewAndGetEvaluation();
						}}
					>
						GET EVALUATION
					</Button>
				);
			}
		} catch (e) {
			console.log(e.message);
			return <div>Please request for a regeneration of the evaluation.</div>;
		}
	};

	const Recording = () => {
		return report?.interviewCompleted || !report?.activeSession ? (
			<Box
				sx={{
					padding: "1rem", // Add padding around the player
					maxWidth: "800px", // Max width for the player
					maxHeight: "450px", // Max height for the player
					margin: "auto", // Center the player horizontally
					position: "relative", // Positioning context for the player
					"&:before": {
						// Aspect ratio box trick to maintain aspect ratio (16:9 in this case)
						content: '""',
						display: "block",
						paddingTop: "56.25%", // 16:9 aspect ratio
					},
					"& .video-react-video": {
						// Target the video player itself
						position: "absolute",
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						width: "100%",
						height: "100%",
					},
				}}
			>
				<Player
					className="video-react-video"
					preload="metadata"
					muted={true}
					playsInline
					poster={poster}
					src={`https://procturemeet.s3.ap-southeast-1.amazonaws.com/recordings/${report?._id}/composited-video/${report?.concatenationId}.mp4`}
				>
					<BigPlayButton position="center" />
					<LoadingSpinner />
					<ControlBar>
						<ReplayControl seconds={5} order={2} />
						<ForwardControl seconds={5} order={3} />
					</ControlBar>
				</Player>
			</Box>
		) : (
			<Typography variant="body1" textAlign={"center"}>
				The Interview is On Going
			</Typography>
		);
	};

	const AzurePronunciation = () => {
		const parsedResult = JSON.parse(report?.azurePronunciationResult || "{}");
		const introAudioClipURL = report?.introAudioClipURL;

		return (
			<Box>
				{introAudioClipURL ? (
					<audio controls>
						<source src={introAudioClipURL} type="audio/mpeg" />
						Your browser does not support the audio element.
					</audio>
				) : (
					<Typography variant="p" textAlign={"center"}>
						No Audio Clip URL Found
					</Typography>
				)}
				<JsonView data={parsedResult} shouldExpandNode={allExpanded} style={defaultStyles} />
			</Box>
		);
	};

	const UnscriptedElsa = () => {
		const parsedResult = JSON.parse(report?.unscriptedELSAResult || "{}");
		const introAudioClipURL = report?.introAudioClipURL;

		return (
			<Box>
				{introAudioClipURL ? (
					<audio controls>
						<source src={introAudioClipURL} type="audio/mpeg" />
						Your browser does not support the audio element.
					</audio>
				) : (
					<Typography variant="p" textAlign={"center"}>
						No Audio Clip URL Found
					</Typography>
				)}
				<JsonView data={parsedResult} shouldExpandNode={allExpanded} style={defaultStyles} />
			</Box>
		);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box className="container mx-auto p-4">
				{!isPuppeteer && (
					<IconButton
						onClick={handleToggleModal}
						aria-label="Open settings"
						sx={{
							padding: 0, // Removes extra padding
							margin: 0.8,
							minWidth: "auto", // Prevents extra space
							"&:hover": {
								backgroundColor: "rgba(255, 165, 0, 0.1)",
							},
						}}
					>
						<Settings fontSize="large" />
					</IconButton>
				)}

				<InterviewReportModal
					isOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					setConfigData={setConfigData}
				/>
			</Box>
			<Box className="container mx-auto p-4">
				{!showGetCustomEvalBtn ? null : isLoadingReport ? (
					<Stack justifyContent={"center"} alignItems={"center"} sx={{ padding: "3rem" }}>
						<CircularProgress />
					</Stack>
				) : (
					<DevOptions
						isMakingNewEvalRequest={isMakingNewEvalRequest}
						onSubmit={(data) => {
							completeInterviewAndGetEvaluation(data);
						}}
					/>
				)}

				<Box className="flex justify-center">
					<Box>
						<img
							src={
								configData == null || configData?.logoUrl === null
									? logo
									: configData?.logoUrl
							}
							alt="Zinterview Logo"
							className="h-12 w-12 mx-auto cursor-pointer"
							onClick={() => {
								navigate("/admin");
							}}
						/>
					</Box>
				</Box>
				<Box className="bg-white rounded-lg shadow-md p-4">
					{isLoadingReport ? (
						<>
							<Stack flexDirection={"column"} gap="0.625rem">
								<Skeleton variant="rectangular" width={"auto"} height={30} />
								<Skeleton variant="rectangular" width={"auto"} height={30} />
								<Skeleton variant="rectangular" width={"auto"} height={30} />
							</Stack>
						</>
					) : openingDetails &&
					  openingDetails?.title &&
					  report &&
					  report?._id &&
					  report?.firstName &&
					  report?.lastName &&
					  report?.phoneNumber &&
					  report?.createdAt &&
					  report?.email ? (
						<Box className="grid grid-cols-12">
							<Box className="container mx-auto mb-10 text-sm flex flex-col col-span-8">
								{(!configData || !configData?.name) &&
									report.firstName &&
									report.lastName && (
										<Typography
											variant="h2"
											className="text-2xl font-bold mb-2 text-purple-900"
										>
											{report.firstName + " " + report.lastName}
										</Typography>
									)}
								{openingDetails?.title && (
									<Typography component="h3" className="font-bold">
										{openingDetails?.title}
									</Typography>
								)}
								{(!configData || !configData?.email) && report?.email && (
									<Typography variant="p">{report?.email}</Typography>
								)}
								{(!configData || !configData?.phone) && report?.phoneNumber && (
									<Typography variant="p">{`Phone: ${report.phoneNumber}`}</Typography>
								)}
								{(!configData || !configData?.interviewDate) && report?.createdAt && (
									<Typography variant="p">{`Interview Date: ${getFormattedDateTime2(
										report.createdAt,
									)}`}</Typography>
								)}
								{/* {report?.userTimeZone && report?.interviewStartTime ? (
									<Typography variant="p">{`Interview Start Time: ${new Date(report?.interviewStartTime).toISOString()}, ${report?.userTimeZone ?? ""}`}</Typography>
								) : (
									""
								)} */}
								{(!configData || !configData?.interviewEndDate) &&
									report?.interviewEndTime && (
										<Typography variant="p">{`Interview Ended: ${getFormattedDateTime2(
											report?.interviewEndTime,
										)}`}</Typography>
									)}
								{openingDetails && (
									<Typography variant="p">{`Experience Range: ${openingDetails.minExperience}-${openingDetails.maxExperience} years`}</Typography>
								)}
								{(!configData || !configData?.trustScore) && report?.trustScore !== 0 && (
									<Box className="flex flex-row items-center">
										<Typography variant="p">{`Trust Score: ${report?.trustScore}`}</Typography>
										<Tooltip
											title="This score indicates how many times the candidate moved away from the tab. For example, a score of -3 means the candidate moved away from the tab 3 times."
											arrow
										>
											<i className="fas fa-info-circle ml-2 mt-[2px] text-gray-500 cursor-pointer"></i>
											{/* If using MUI icons, you can replace the above <i> with this:
            <InfoIcon className="ml-2 text-gray-400 cursor-pointer" /> */}
										</Tooltip>
									</Box>
								)}
								{(!configData || !configData?.cheatingScore) && (
									<CheatingDataPopover interviewReportId={params.interviewReportId} />
								)}
							</Box>
							<Box className="col-span-4 flex justify-center">
								{(!configData || !configData?.candidatePhoto) &&
									report?.candidatePhotoFileNameInS3 && (
										<NetworkImage
											src={`https://procturemeet.s3.ap-southeast-1.amazonaws.com/candidateInfos/${report.candidatePhotoFileNameInS3}`}
											alt={"Candidate Photo"}
											className="rounded-lg w-[140px] h-[180px] object-cover"
										/>
									)}
							</Box>
						</Box>
					) : null}
					<Box className="mb-8 border-b-0 text-sm">
						{isLoadingReport ? (
							<Stack
								justifyContent={"center"}
								flexDirection={"row"}
								width={"100%"}
								alignItems={"center"}
							>
								{" "}
								<CircularProgress style={{ marginRight: "1rem" }} /> Fetching Results ...{" "}
							</Stack>
						) : report ? (
							<>
								<Box id="Tabs" className="flex flex-wrap">
									{!isDownloading && (
										<Box id="EvaluationTab" className="-mb-px mr-1">
											<Typography
												className={`bg-white inline-block py-2 px-4 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm  ${
													currentTab === tabs.EVALUATION
														? `text-purple-800 font-semibold  border-purple-800`
														: "border-gray-100"
												}`}
												onClick={() => setCurrentTab(tabs.EVALUATION)}
											>
												Evaluation
											</Typography>
										</Box>
									)}
									{!isPuppeteer && !isDownloading && report?.concatenationId && (
										<Box id="RecordingTab" className="mr-1">
											<Typography
												className={`
                            'bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm  ${
								currentTab === tabs.RECORDING
									? `text-purple-800 font-semibold border-purple-800`
									: "border-gray-100"
							}`}
												onClick={() => setCurrentTab(tabs.RECORDING)}
											>
												Recording
											</Typography>
										</Box>
									)}
									{!isPuppeteer && !isDownloading && (
										<Box id="IntrwMsgsTab" className="mr-1">
											<Typography
												className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm ${
													currentTab === tabs.INTERVIEW_MESSAGES
														? `text-purple-800 font-semibold border-purple-800`
														: "border-gray-100"
												}`}
												onClick={() => setCurrentTab(tabs.INTERVIEW_MESSAGES)}
											>
												Interview Messages
											</Typography>
										</Box>
									)}
									{!isDownloading &&
										unscripted === "show" &&
										report?.unscriptedELSAResult &&
										report?.introAudioClipURL && (
											<Box id="UnscriptedElsaTab">
												<Typography
													className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm font-semibold ${
														currentTab === tabs.UNSCRIPTED_ELSA
															? `text-purple-800 font-semibold border-purple-800`
															: "border-gray-100"
													}`}
													onClick={() => setCurrentTab(tabs.UNSCRIPTED_ELSA)}
												>
													ELSA Communication Evaluation
												</Typography>
											</Box>
										)}
									{!isDownloading &&
										unscripted === "show" &&
										report?.azurePronunciationResult &&
										report?.introAudioClipURL && (
											<Box id="AzurePronunciationTab">
												<Typography
													className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm font-semibold ${
														currentTab === tabs.AZURE_PRONUNCIATION
															? `text-purple-800 font-semibold border-purple-800`
															: "border-gray-100"
													}`}
													onClick={() => setCurrentTab(tabs.AZURE_PRONUNCIATION)}
												>
													Azure Communication Evaluation
												</Typography>
											</Box>
										)}
									{!isDownloading && faceDetectionFrames?.length > 0 && (
										<Box id="FaceDetectionTab">
											<Typography
												className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm ${
													currentTab === tabs.FACE_DETECTION_FRAMES
														? `text-purple-800 font-semibold border-purple-800`
														: "border-gray-100"
												}`}
												onClick={() => setCurrentTab(tabs.FACE_DETECTION_FRAMES)}
											>
												Face Detection Frames
											</Typography>
										</Box>
									)}
									{currentTab === tabs.EVALUATION &&
										(report.communicationEvaluation || report.evaluation) && (
											<DownloadEvaluationReportMenu
												isDownloading={isDownloading}
												setIsDownloading={setIsDownloading}
												fileName={`IntrwReport_${report.firstName}`}
												setExpandedStates={setExpandedStates}
											/>
										)}
								</Box>
							</>
						) : (
							<Typography variant="body1">Contact Admin.</Typography>
						)}
					</Box>
					{currentTab === tabs.EVALUATION && report && <Evaluation report={report} />}
					{currentTab === tabs.RECORDING && <Recording />}
					{currentTab === tabs.INTERVIEW_MESSAGES && (
						<InterviewMessages source="NIT" report={report} setReport={setReport} />
					)}
					{currentTab === tabs.AZURE_PRONUNCIATION && <AzurePronunciation />}
					{currentTab === tabs.UNSCRIPTED_ELSA && <UnscriptedElsa />}
					{currentTab === tabs.FACE_DETECTION_FRAMES && (
						<FaceDetectionFrames
							_id={report?._id}
							faceDetectionFrames={faceDetectionFrames}
							setFaceDetectionFrames={setFaceDetectionFrames}
						/>
					)}
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default InterviewReport;

function snakeCaseToHeaders(heading) {
	if (heading === "score_in_percentage") heading = "score";
	if (!heading || heading.trim() === "") {
		return "";
	}
	return heading
		.split("_")
		.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
}

const RenderStrengthsAndWeaknesses = ({
	items,
	label,
	needsBottomPadding = false,
	needsPaddingForHeading = true,
}) => {
	return (
		<>
			{items?.length > 0 && (
				<Box
					className={clsx(
						"mb-2  flex flex-col gap-1",
						needsBottomPadding && "mt-3",
						needsPaddingForHeading && "px-4",
					)}
				>
					<>
						<Typography
							variant="p"
							className={clsx("pb-1", needsPaddingForHeading ? "font-bold" : "font-medium")}
						>
							{snakeCaseToHeaders(label)}
						</Typography>
						<div className={clsx(!needsPaddingForHeading && "px-4")}>
							<ul>
								{items.map((item, i) => {
									return (
										<li key={i} className="list-disc">
											<Markdown>{item}</Markdown>
										</li>
									);
								})}
							</ul>
						</div>
					</>
				</Box>
			)}
		</>
	);
};

const RatingsComponent = ({ ratings }) => {
	return (
		<Box className="col-span-2 text-sm">
			<Typography
				sx={{ breakInside: "avoid-page" }}
				variant="h3"
				className="text-xl font-bold mb-4 text-purple-900 mt-4"
			>
				Skills Rating
			</Typography>

			{ratings && Array.isArray(ratings) && ratings.length > 0 ? (
				<Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
					{ratings.map((eachRating, i) => {
						const { skill_name = " ", rating = 0, evaluation = "" } = eachRating;
						return (
							<Box key={i} className="flex flex-col bg-gray-50 rounded-lg p-4">
								<Typography variant="p" className="font-bold">
									{skill_name}
								</Typography>
								<Box className="flex flex-row items-center">
									<Typography variant="p" className="font-bold mr-1">
										Rating:
									</Typography>
									<Rating
										name="read-only-rating"
										value={rating}
										precision={0.1}
										max={5}
										readOnly
										className="rating-comp-fix pt-1"
										sx={{
											fontSize: "0.8rem", // This sets the font size equivalent to a normal <p> element
											// '& .MuiRating-iconFilled':
											// 	{
											// 		color: '#000', // This sets the filled stars to black
											// 	},
											"& .MuiRating-iconEmpty": {
												color: "transparent", // This makes the empty stars invisible or they could be set to the background color
											},
											// '& .MuiRating-iconHover':
											// 	{
											// 		color: '#000', // Optional: if you want to change the hover color as well
											// 	},
										}}
									/>
								</Box>
								<Box>
									<Typography variant="p font-bold">Evaluation:</Typography>
									<Typography variant="p">{` ${evaluation}`}</Typography>
								</Box>
							</Box>
						);
					})}
				</Box>
			) : null}
		</Box>
	);
};

const CheatingDataPopover = ({ interviewReportId }) => {
	const { data: cheatingScoresForCandidate, isLoading: isCheatingScoreLoading } =
		useGetCheatingScoreForCandidateQuery(interviewReportId);

	if (isCheatingScoreLoading) {
		return null;
	}

	if (!isCheatingScoreLoading && !cheatingScoresForCandidate?.score) {
		return null;
	}

	const score = cheatingScoresForCandidate.score;

	return <CheatingScoreInfo score={score} />;
};

const CheatingScoreInfo = ({ score }) => {
	const { likelihood_of_cheating, verdict } = score;

	return (
		<div className="flex flex-row gap-2 items-center">
			<Typography variant="p">
				{`AI-Cheating Score: ${likelihood_of_cheating / 10} `} <strong>/ 10</strong>
			</Typography>
			<FlagComponent score={likelihood_of_cheating} verdict={verdict} />
		</div>
	);
};
